import React, { FC, useContext, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { setTheme } from "helpers/Theme"
import { Query } from "typings/graphql"
import { App } from "components/App/App"
import styled from "styled-components"
import { mq } from "styles"
import { Cart } from "components/Cart/Cart"
import { tracking } from "utils/tracking"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { StoreContext } from "contexts/StoreContext"
import { LoadingContainer } from "components/Cart/Cart.styles"
import { siteConstants } from "constants/site"

const Container = styled.div`
  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  padding-bottom: 64px;

  ${mq.minWidth("sm")} {
    padding-bottom: 80px;
  }

  ${mq.minWidth("lg")} {
    padding-bottom: 96px;
  }

  ${LoadingContainer} {
    min-height: ${() => `calc(100vh - ${siteConstants.headerHeight}px)`};
  }
`

type PageProps = {
  data: Query
}

const CartPageTracking: React.FC = () => {
  const trackingFired = useRef(false)
  const productPathPrefix = useProductPathPrefix()
  const { checkout } = useContext(StoreContext)

  useEffect(() => {
    if (!checkout || trackingFired.current) {
      return
    }

    tracking.cartViewed(checkout, productPathPrefix)
    trackingFired.current = true
  }, [checkout, trackingFired])

  return null
}

const CartPage: FC<PageProps> = ({ data }) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  return (
    <App theme={theme} layout="page" mode="dark">
      <Container>
        <CartPageTracking />
        <Cart
          gridProps={{
            base: "1 [10] 1",
            md: "1 [14] 1",
            lg: "7 [10] 7",
          }}
        />
      </Container>
    </App>
  )
}

export const pageQuery = graphql`
  query CartPage {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`

export default CartPage
